import React, { PureComponent } from 'react';
import 'moment/locale/da.js';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import CalendarLine from './CalendarLine';
import './Calendar.css';
import './custom-datepicker.css';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import {actionCreators as plannedItemActions} from '../../store/PlannedItemStore';
import {actionCreators as calendarActions} from '../../store/CalendarStore';
import * as EventStore from '../../store/EventStore';
import moment from 'moment';
import {Day} from './Day';

class Calendar extends PureComponent {

    componentDidMount() {
        this.props.calendarActions.setStart(moment().add(0, 'days').startOf('day'));
        this.props.plannedItemActions.getAllPlans();
        this.props.plannedItemActions.setStartTime(moment().set('hours', 7).set('minutes', 0));
        this.props.plannedItemActions.setEndTime(moment().set('hours', 15).set('minutes', 30));
    }

    render(){
        const props = this.props;
        const calendarState = props.state.calendarState;
        const colorCodes = props.state.plannedItemState.colorCodes;
        const plans = props.state.plannedItemState.plans;
        const teams = props.state.teamState.teams;
        const teamToShow = props.state.calendarState.teamToShow;
        const events = props.eventState.events;
        const eventColor = props.state.plannedItemState.colorCodes.find(x => x.systemName === 'Event');

        const pPlans = [];

        /*
            pPlans == et array af arrays - indexerne som indeholder noget er dem som matcher personId'er
            Hvis index (planens personens id) ikke indeholder noget, tildeles den et tomt array
            --> derefter pushes planen til enten det nyoprettede tomme array, ELLER til det allerede eksisterende array
        */

        plans.forEach(x => {
            if(!pPlans[x.personId]) pPlans[x.personId] = [];
            pPlans[x.personId].push(x);
        });

        let hours = [];
        for(let i = 0; i < 24; i++){
            hours.push(moment(calendarState.days[0]).startOf('day').add(i, 'hours'));
        }
        const createdPeople = {};
        const createUniqueKey = (p) => {
            let key = p.id;

            if(createdPeople.hasOwnProperty(p.id)){
                key = `${key}-${createdPeople[p.id]}`;
                createdPeople[p.id] = createdPeople[p.id] + 1;
            }else {
                createdPeople[p.id] = 1;
            }
            return key;
        }
        const renderLine = (p, i) => {
            const key = createUniqueKey(p);

            const dateView = calendarState.numberOfDays !== 1;
            if(p.hidden){
                return;
            }
            return(
                <CalendarLine
                    key={key}
                    person={p}
                    plans={pPlans[p.id] || []} // her sendes det specifikke array for den persons planer med 
                    events={events}
                    eventColor={eventColor}
                    days={dateView ? calendarState.days : hours}
                    dayWidth={dateView ? 100 / calendarState.numberOfDays : 100/24}
                    granularity={dateView ? 'date' : 'hour'}
                    highLightPersonId={calendarState.highLightPersonId}
                    onClick={() => this.props.calendarActions.setHighLight(p.id)}
                    startTime={calendarState.days[0]}
                    totalTime={calendarState.numberOfDays}
                    plannedItemActions={props.plannedItemActions}
                />
            )
        };

        const renderLegend = () => {
            return colorCodes.map((cc) => {
                const style = {color: cc.hexColor};
                return (<li className='legend' key={cc.name} style={style}><p>{cc.name}</p></li>)
            });
        };

        const changeNumberOfDays = (nod) => {
            props.calendarActions.setNumberOfDays(nod);
        };

        const renderWeeks = (days) => {
            let arr = [];
            let lastWeek = null;
            let pointer = -1;

            days.forEach(d => {
                const curWeek = d.week();
                if(curWeek === lastWeek){
                    arr[pointer].count++;
                }
                else{
                    pointer++;
                    arr[pointer] = {week: curWeek, count: 1};
                }
                lastWeek = curWeek;
            });



            return arr.map((e) => {
                const style = { width: `${calendarState.dayWidth * e.count}%`};
                return <div className="dd-week" key={`${e.week}-${e.count}`} style={style}>UGE {e.week}</div>;
            } );
        };

        const renderMonth = (entry) => {
            const monthNames = ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'];
            const style = { width: `${calendarState.dayWidth * entry.count}%`};
            return(
                <div className="dd-month" key={`${entry.month}-${entry.count}`} style={style}>{monthNames[entry.month]}</div>
            );
        };

        const renderMonths = (days) => {
            let arr = [];
            let lastMonth = null;
            let pointer = -1;

            days.forEach(d => {
                const curMonth = d.month();
                if(curMonth === lastMonth){
                    arr[pointer].count++;
                }
                else{
                    pointer++;
                    arr[pointer] = {month: curMonth, count: 1};
                }
                lastMonth = curMonth;
            });

            return arr.map((e) => renderMonth(e));
        };

        const renderHours = () => {
            const arr = [
                '00:00', '01:00', '02:00', '03:00', '04:00', '05:00',
                '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
                '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
                '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
            return arr.map((i) => {
                const style = { width: `${90/24}%`};
                return <div className="dd-hour" key={i} style={style}>{i}</div>;
            } );
        };
        
        const personMap = () => {

            let persons = [];
            if(teams.length < 1) return null;

            for(let team of teams)
            {
                // lav variabel som bestemmer om den skal være hidden eller ej
                // hvis teamToShow == 0 skal alle vises
                // dvs. hvis det IKKE er det valgte filtreringsteam OG det ikke er 0 (fordi så er det alle teams) - SÅ skal den gemmes
                const hidden = teamToShow != 0 && teamToShow != team.id;

                // personen som er leader får en prop "leader" = true, og pushes til persons-array'et som alle andre
                team.teamLeader.leader = true;
                team.teamLeader.hidden = hidden;
                persons.push(team.teamLeader);


                team.members.forEach(member => {
                    member.hidden = hidden;
                    persons.push(member)
                });
            };

            return persons.map((p, i) => renderLine(p,i));
        };

        const createTeamOptions = () => {
            return teams && teams.map(t => {
                return <option key={t.id} value={t.id}> {t.name} </option>
            });
        };

        const changeTeamToShow = (id) => {
            props.calendarActions.setTeamToshow(id);
        }

        const calendarChooser = () => {
            return(
                <div className="calender-chooser">
                    <button className={'period-pick' + (calendarState.numberOfDays === 180 ? ' current' : '')} onClick={() => changeNumberOfDays(180)}>6 måneder</button>
                    <button className={'period-pick' + (calendarState.numberOfDays === 90  ? ' current' : '')} onClick={() => changeNumberOfDays(90)}>3 måneder</button>
                    <button className={'period-pick' + (calendarState.numberOfDays === 30  ? ' current' : '')} onClick={() => changeNumberOfDays(30)}>1 måned</button>
                    <button className={'period-pick' + (calendarState.numberOfDays === 14  ? ' current' : '')} onClick={() => changeNumberOfDays(14)}>2 uger</button>
                    <button className={'period-pick' + (calendarState.numberOfDays === 1  ? ' current' : '')} onClick={() => changeNumberOfDays(1)}>Dagsoverblik</button>
                    <div className={'period-pick'}>|</div>
                    <button className={'period-pick'} onClick={() => props.calendarActions.setStart(moment().startOf('day'))}>Nu</button>

                  

                    <div className="datepicker-container">
                        <Flatpickr value={moment(calendarState.start).format('YYYY-MM-DD')} onChange={d => props.calendarActions.setStart(new Date(d[0]))} className="date-picker-custom"/>
                    </div>

                    <span className="header-name"></span>
                    {/**dropdown til valg af hvilke/t team/s der virker
                     * For at tilføje denne dropdown til andre, tilføj noget fra: 
                     * 1. Calender.css
                     * 2. CalenderStore.js ( teamToShow: 0 i initial state og metoden  CALENDER_SET_TEAM_TO_SHOW og en anden metode)
                    */}
                    <div className="teampicker-container">
                        <select className="team-picker" onChange={e => changeTeamToShow(e.target.value)} value={teamToShow}>
                            <option key={0} value={0}> Alle teams </option>
                            {createTeamOptions()}
                        </select>
                    </div>
                 
                </div>
            );
        };

        const dayComponents = calendarState.days.map((day, i) => <Day key={i} size={calendarState.dayWidth} day={day} onClick={props.calendarActions.setStart}/>);
        const monthComponents = renderMonths(calendarState.days);
        const weekComponents = renderWeeks(calendarState.days);
        const colorComponents = renderLegend();
        const lineComponents = personMap();
        const minutesSinceMidnight = moment().diff(moment().startOf('day'), 'minutes');

        return (
            <div className="content">
                {calendarChooser()}
                <div className='calendar'>
                    <div className="header">
                        <div className="row">
                            <span className="header-name"/>
                            {monthComponents}
                        </div>
                        <div className="row">
                            <span className="header-name"/>
                            {weekComponents}
                        </div>
                        <div className={'row c-' + (calendarState.numberOfDays)}>
                            <span className="header-name"/>
                            {dayComponents}
                        </div>
                        {calendarState.numberOfDays <= 1 ?
                            <div className={'row'}>
                                <span className="header-name"/>
                                {renderHours()}
                            </div> : null
                        }
                    </div>
                    <div className='calendar-body'>
                        {lineComponents}
                        {calendarState.numberOfDays <= 1 ? <div className='hour-line' style={{left: `calc(${minutesSinceMidnight / 1440 * 90}% + 10%)`}}/> : null }
                    </div>
                    <ul className="legend-container">
                        {colorComponents}
                    </ul>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({state: state, eventState: state[EventStore.reducerName]}),
    dispatch => ({
        plannedItemActions: bindActionCreators(plannedItemActions, dispatch),
        calendarActions: bindActionCreators(calendarActions, dispatch)
    })
)(Calendar);