import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {actionCreators as staticMapActions} from '../store/StaticMapStore';


import Menu from '../components/Menu';
//import MapImage from '../images/map_gladsaxe2.jpg';
import config from '../config';
import './StaticMap.css';
import './Documents.css';


const StaticMap = props => {

    const staticMaps = props.staticMapStore.staticMaps;
    const activeStaticMap = props.staticMapStore.activeStaticMap;

    return(
        <div className="App">
            <Menu/>
            <div className='maps'>
                <div className='maps-container'>
                    {staticMaps && staticMaps.map((staticMap, index) => {
                        return(
                            <div key={index} onClick={() => props.staticMapActions.setActive(staticMap)} className={`map ${activeStaticMap && staticMap.id === activeStaticMap.id ? 'active' : ''}`}>
                                {staticMap.name}
                            </div>
                        );
                    })}
                </div>
                {activeStaticMap &&
                <div className='static-map'>
                    <img src={activeStaticMap.map} alt={activeStaticMap.name}/>

                    {/**
                    <img src={`/static/media/images/${activeStaticMap.path}`} alt={activeStaticMap.name}/>
                    */}
                </div>
                }
            </div>
        </div>
    )
};

export default connect(
    state => ({
        staticMapStore: state.staticMapStore,
    }),
    dispatch => ({
        staticMapActions: bindActionCreators(staticMapActions, dispatch)
    })
)(StaticMap);