import React, { Component } from 'react';
import './css/App.css';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import {actionCreators as routerActions} from './store/RouterActions';
import {actionCreators as winterActions} from './store/WinterDutyStore';
import {actionCreators as personActions} from './store/PersonStore';
import {actionCreators as plannedItemActions} from './store/PlannedItemStore';
import {actionCreators as teamActions} from './store/TeamStore';
import {actionCreators as slideActions} from './store/SlideStore';
import {actionCreators as tv2NewsActions} from './store/Tv2NewsStore';
import * as GridStore from './store/GridStore';
import Menu from './components/Menu';
/*import TeamList from './components/organization/TeamList';*/
import moment from 'moment';
/*import DrNews from "./components/slides/DrNews";*/
/*import SlideShow from "./components/slides/SlideShow";*/
import CustomTeamList from './components/organization/CustomTeamList';

class Home extends Component {

    componentDidMount() {
        this.props.plannedItemActions.setInterval(moment().startOf('day'), moment().add(5).startOf('day'));
        this.props.plannedItemActions.getAllPlans();

        this.updateDates  = setInterval(() => {
            this.props.plannedItemActions.setInterval(moment().startOf('day'), moment().add(5).startOf('day'));
        }, 1000*180);
    }

    componentWillUnmount() {
        clearInterval(this.updateDates);
    }

    render() {
        return (
            <div className="App">
                <Menu/>
                <CustomTeamList/>    
                {/*
                <div className='news-slide'>
                    <DrNews/>
                </div>
                <div className='slide-show slide-show-1'>
                    <SlideShow slideShowId={10018}/>
                </div>
                <div className='slide-show slide-show-2'>
                    <SlideShow slideShowId={10019} />
                </div>
                */}
            </div>
        );
  }
}

export default connect(
    state => ({state: state}),
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch),
        winterActions: bindActionCreators(winterActions, dispatch),
        personActions: bindActionCreators(personActions, dispatch),
        plannedItemActions: bindActionCreators(plannedItemActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        slideActions: bindActionCreators(slideActions, dispatch),
        tv2NewsActions: bindActionCreators(tv2NewsActions, dispatch),
        gridActions: bindActionCreators(GridStore.actionCreators, dispatch)
    })
)(Home);
