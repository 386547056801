/*
import axios from 'axios';
import config from '../config';
import {createAuth} from "../Helpers";
*/

import mapImage1 from '../images/luftfoto_converted_2.png';
import mapImage2 from '../images/skærmkort_converted_2.png';

export const reducerName = 'staticMapStore';

const STATIC_MAP_SET_ACTIVE = 'STATIC_MAP_SET_ACTIVE';
const STATIC_MAP_SET_INITIAL_STATE = 'STATIC_MAP_SET_INITIAL_STATE';

const staticMaps = [
    {
        id: 1,
        name: "Ortofoto",
        map: mapImage1
    },
    {
        id: 2,
        name: "Skærmkort",
        map: mapImage2
    }
];

const initialState = {
    staticMaps: staticMaps,
    activeStaticMap: null
};

export const actionCreators = {
    setInitialState: () => async(dispatch, getState) => {
        if(getState().staticMapStore.activeStaticMap === null && initialState.staticMaps.length > 0){
            dispatch({type: STATIC_MAP_SET_INITIAL_STATE, data: initialState});
        }
    },
    setActive: staticMap => async(dispatch, getState) => {
        dispatch({type: STATIC_MAP_SET_ACTIVE, staticMap});
    }
};

const reducerMethods = {
    STATIC_MAP_SET_INITIAL_STATE: (state, action) => {
        return{
            ...state,
            staticMaps: action.data.staticMaps,
            activeStaticMap: action.data.staticMaps[0],
        }
    },
    STATIC_MAP_SET_ACTIVE: (state, action) => {
        return{
            ...state,
            activeStaticMap: action.staticMap,
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};